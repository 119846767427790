import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../../templates/Layout";

import * as styles from "./committee.module.css"

export const pageQuery = graphql`
    query Committee {
        mdx(slug: {eq: "committee"}) {
            body
            frontmatter {
              title
            }
        }
    }
`


class Committee extends React.Component {
    render() {
        const data = this.props.data.mdx;

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
                className={styles.wrapper}
            >
                <h1>{data.frontmatter.title}</h1>
                <MDXRenderer>
                    {data.body}
                </MDXRenderer>
            </Layout>
        )
    }
}
        
export default Committee